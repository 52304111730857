.App {
  display: flex;
  min-height: 100vh;
  /* flex-direction: column; */
  /* background-color: #f8f8fb; */
}
/* @font-face {
    font-family: "Graphik-Extralight";
    src: url("./assets/fonts/Graphik-Extralight.ttf");
    font-style: medium;
    font-display: block;
}
@font-face {
    font-family: "Graphik-Light";
    src: url("./assets/fonts/Graphik-Light.ttf");
    font-style: medium;
    font-display: block;
}
@font-face {
    font-family: "Graphik-Regular";
    src: url("./assets/fonts/Graphik-Regular.ttf");
    font-style: medium;
    font-display: block;
} */

body {
  padding-right: 0px !important;
  overflow-y: auto !important;
}

@font-face {
  font-family: "Graphik-Extralight";
  src: url("./assets/fonts/Graphik-Extralight.eot");
  src: url("./assets/fonts/Graphik-Extralight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Graphik-Extralight.woff") format("woff"),
    url("./assets/fonts/Graphik-Extralight.ttf") format("truetype");

  font-style: medium;
  /* font-weight: 500; */
  font-display: block;
}
@font-face {
  font-family: "Graphik-Light";
  src: url("./assets/fonts/Graphik-Light.eot");
  src: url("./assets/fonts/Graphik-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Graphik-Light.woff") format("woff"),
    url("./assets/fonts/Graphik-Light.ttf") format("truetype");
  font-style: medium;
  /* font-weight: 500; */
  font-display: block;
}
@font-face {
  font-family: "Graphik-Regular";
  src: url("./assets/fonts/Graphik-Regular.eot");
  src: url("./assets/fonts/Graphik-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Graphik-Regular.woff") format("woff"),
    url("./assets/fonts/Graphik-Regular.ttf") format("truetype");
  font-style: medium;
  /* font-weight: 500; */
  font-display: block;
}

@media only screen and (max-width: 670px) {
  .phaseBox {
    margin: 0 auto !important;
  }
}
